import Icon from '@carbonfact/ui-components/src/Icon';
import { Button } from 'app/components/Button';
import classNames from 'classnames';

type EditIconButtonProps = {
  onClick: () => void;
  className?: string;
};
const EditIconButton = ({ onClick, className }: EditIconButtonProps) => {
  return (
    <Button.Default
      aria-label="edit"
      variant="invisible"
      onClick={onClick}
      className={classNames(className, 'flex items-center !p-0')}
    >
      <Icon
        width={24}
        height={24}
        className="text-carbon-500"
        icon={{
          type: 'line',
          source: 'local',
          name: 'edit',
        }}
      />
    </Button.Default>
  );
};
export default EditIconButton;
