import classNames from 'classnames';
import type { ReactNode } from 'react';

export function Tag({
  className,
  onClick,
  style,
  children,
}: {
  className?: string;
  onClick?: () => void;
  style?: React.CSSProperties;
  children: ReactNode;
}) {
  return (
    <div
      onClick={onClick}
      className={classNames(
        'text-sm leading-none font-medium px-1 rounded h-5 flex items-center whitespace-nowrap',
        className?.includes('text-') ? '' : 'text-gray-800',
        className?.includes('bg-') ? '' : 'bg-gray-200',
        className,
      )}
      style={style}
    >
      {children}
    </div>
  );
}
