'use client';
import MissingDataError from 'app/components/Error/Custom/MissingDataError';
import Loader from 'app/components/Loader';
import useEndpoint from 'app/lib/useEndpoint';

import Target from './Target';

const TargetSection = () => {
  const { data: targets, isLoading, error } = useEndpoint('/settings/targets');

  if (error) {
    return <MissingDataError />;
  }

  if (isLoading) {
    return <Loader type="inline" />;
  }

  if (!targets) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      <Target scope={12} targets={targets} />
      <hr />
      <Target scope={3} targets={targets} />
    </div>
  );
};

export default TargetSection;
