'use client'; // needed for SWR
import { useTranslations } from 'next-intl';
import Link from 'next/link';
// note we do NOT use our own useSWR hook here, because we don't want to send Niklas API tokens to our status page API
import useSWR from 'swr';

import Banner from './Banner';

const INCIDENT_PAGE_LINK = 'https://carbonfact1.statuspage.io/';
const INCIDENT_STATUS_API_ENDPOINT =
  'https://carbonfact.statuspage.io/api/v2/status.json';

interface IncidentApiResponse {
  page: {
    id: string;
    name: string;
    url: string;
    time_zone: string;
    updated_at: string;
  };
  status: {
    description: string;
    indicator: 'none' | 'something-else';
  };
}

export default function AutomaticIncidentBanner() {
  const { data } = useSWR<IncidentApiResponse>(
    INCIDENT_STATUS_API_ENDPOINT, // fetch key
    () =>
      fetch(INCIDENT_STATUS_API_ENDPOINT, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((res) => res.json())
        .catch((err) => {
          console.error('Error fetching incident status', err);
          return null;
        }),
    {
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    },
  );

  // Fail-safe condition to prevent the banner from showing in unexpected states or fetching errors
  if (
    typeof data?.status.indicator === 'string' &&
    data.status.indicator !== 'none'
  ) {
    return (
      <section aria-live="assertive" className="sticky top-0">
        <Banner type="warning">
          <IncidentBannerContent />
        </Banner>
      </section>
    );
  }

  return null;
}

function IncidentBannerContent() {
  const t = useTranslations();
  return (
    <div className="flex flex-row gap-2 items-center">
      <h1 className="text-base">
        {t('AutomaticIncidentBanner.incidentInProgress')}
      </h1>

      <hr className="[height:2px] w-3 bg-gray-700 my-auto" />

      <p className="text-xs font-normalk">
        {t.rich('AutomaticIncidentBanner.technicalIssuesMessage', {
          Link: (chunk) => (
            <Link
              className="underline"
              href={INCIDENT_PAGE_LINK}
              target="_blank"
            >
              {chunk}
            </Link>
          ),
        })}
      </p>
    </div>
  );
}
