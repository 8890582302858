import { Button } from 'app/components/Button';
import Container from 'app/components/Container';
import { useTranslations } from 'next-intl';
import { useState } from 'react';

import Sidebar from './Sidebar';
import { ModelingSection } from './modeling/ModelingSection';
import Preferences from './preferences/Preferences';
import Targets from './targets/Targets';
import Teammates from './teammates/Teammates';

type SettingsModalProps = {
  onClose: () => void;
};

const SettingsModal = ({ onClose }: SettingsModalProps) => {
  const [activeSection, setActiveSection] = useState('reductionTargets');
  const t = useTranslations();

  return (
    <div className="flex h-full">
      {/* Sidebar component, swap this element with another sidebar if you like */}
      <Sidebar
        activeSection={activeSection}
        onActiveSectionChange={setActiveSection}
      />
      <Container
        type="MD"
        title={t(`SettingsModal.${activeSection}`)}
        actions={
          <Button.Icon
            onClick={onClose}
            variant={'secondary'}
            icon={{
              source: 'hero',
              type: 'line',
              name: 'XMarkIcon',
            }}
          />
        }
      >
        {activeSection === 'preferences' && (
          <div>
            <Preferences />
          </div>
        )}
        {activeSection === 'account' && (
          <div>{t('Settings.accountSettings')} </div>
        )}
        {activeSection === 'general' && (
          <div>{t('Settings.generalSettings')} </div>
        )}
        {activeSection === 'teammates' && <Teammates />}
        {activeSection === 'reductionTargets' && <Targets />}
        {activeSection === 'modeling' && <ModelingSection />}
      </Container>
    </div>
  );
};

export default SettingsModal;
