'use client';

import BaselineSection from './BaselineSection';
import TargetSection from './TargetSection';

const Targets = () => {
  return (
    <div className="flex flex-col gap-4">
      <BaselineSection />
      <hr />
      <TargetSection />
    </div>
  );
};

export default Targets;
