import { withAuthenticationRequired } from '@auth0/auth0-react';
import type { ComponentType, ReactNode } from 'react';

type ComponentWithChildren = ComponentType<{
  children: ReactNode;
  params: Promise<{
    locale: string;
  }>;
}>;

export default function withAuthWall(layoutComponent: ComponentWithChildren) {
  return withAuthenticationRequired(layoutComponent, {
    loginOptions: {
      openUrl: (url) => window.location.replace(url),
    },
  });
}
