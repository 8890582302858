import InPageError from 'app/components/Error/InPageError';

interface MissingDataErrorProps {
  title?: string;
  description?: string;
}

export default function MissingDataError({
  title = `We don't have enough data yet!`,
  description = 'We need more data to display this page. We will update it as soon as we have enough data.',
}: MissingDataErrorProps) {
  return (
    <InPageError title={title} description={description} icon="circle-stack" />
  );
}
