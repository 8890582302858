type NavigationTitleProps = {
  children: React.ReactNode;
};
const NavigationTitle = ({ children }: NavigationTitleProps) => {
  return (
    <div className="py-0.5 flex flex-col">
      <div className="px-2 text-xs font-semibold text-gray-500 mb-1">
        {children}
      </div>
    </div>
  );
};
export default NavigationTitle;
