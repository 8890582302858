import {
  CircleStackIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/20/solid';

interface ErrorIconProps {
  icon: InPageErrorProps['icon'];
}

function ErrorIcon({ icon }: ErrorIconProps) {
  switch (icon) {
    case 'circle-stack':
      return (
        <CircleStackIcon width={24} height={24} className="text-carbon-600" />
      );
    case 'wrench-screwdriver':
      return (
        <WrenchScrewdriverIcon
          width={24}
          height={24}
          className="text-carbon-600"
        />
      );
    default:
      return null;
  }
}

interface InPageErrorProps {
  icon: 'circle-stack' | 'wrench-screwdriver';
  title: string;
  description: string;
}

export default function InPageError({
  icon,
  title,
  description,
}: InPageErrorProps) {
  return (
    <div className="py-4" role="alert">
      <div className="pt-4 gap-3">
        <div className="flex justify-center">
          <div className="flex flex-col  gap-2 justify-center">
            {icon && (
              <div className="flex justify-center">
                <div className="flex p-2 rounded-[40px] bg-carbon-100">
                  <ErrorIcon icon={icon} />
                </div>
              </div>
            )}
            <div className="text-carbon-700 leading-6 font-medium w-full text-center">
              {title}
            </div>
            <div className="text-carbon-700 leading-5 font-normal text-sm w-full text-center">
              {description}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
