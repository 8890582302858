import Icon from '@carbonfact/ui-components/src/Icon';
import classNames from 'classnames';
import type { ReactNode } from 'react';

export type BannerProps = {
  type: 'info' | 'warning';
  children: ReactNode;
};
const Banner = ({ type, children }: BannerProps) => {
  let color = '';
  switch (type) {
    case 'warning':
      color = 'bg-orange-100';
      break;
    case 'info':
      color = 'bg-blue-100';
      break;
  }

  return (
    <div
      role="alert"
      className={classNames(
        color,
        'w-full py-2 px-3 rounded-bl-lg rounded-br-lg justify-start items-center gap-2 inline-flex ',
      )}
    >
      <div className="w-5 h-5 relative">
        <Icon
          icon={{
            source: 'local',
            type: 'solid',
            name: 'warning',
          }}
        />
      </div>
      <div className="grow shrink basis-0 text-carbon-700 text-sm font-medium leading-tight">
        {children}
      </div>
    </div>
  );
};

export default Banner;
