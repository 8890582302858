import type { FetcherError } from 'app/context/SWRContext';
import useSWR, { type SWRConfiguration, type SWRResponse } from 'swr';

import type {
  FilteredEndpoints,
  GenericPossibleEndpoints,
} from './endpoint-utility-types';

// Pick only the endpoints with a response type
// The other ones are not meant to be GET'ed, it doesn't make sense
// to use them with SWR (e.g POST, PATCH... mutations)
type EndpointsWithResponses = FilteredEndpoints<{
  response: unknown;
}>;

type PossibleEndpoints = GenericPossibleEndpoints<EndpointsWithResponses>;

export default function useEndpoint<T extends keyof PossibleEndpoints>(
  url: T | (() => T) | null | undefined,
  options?: SWRConfiguration<PossibleEndpoints[T]['response']>,
): SWRResponse<
  PossibleEndpoints[T]['response'],
  FetcherError | Error | undefined
> {
  const swr = useSWR<
    PossibleEndpoints[T]['response'],
    FetcherError | Error | undefined
  >(url, options);
  return swr;
}
