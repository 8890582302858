import type { PlatformApiEndpoints } from '@carbonfact/shared/src/types/platform';

export type TargetType =
  PlatformApiEndpoints['/metadata']['response']['targets'][0];

export const formatTargetScope = (scope: number): string => {
  switch (scope) {
    case 12:
      return 'Scope 1/2';
    case 3:
      return 'Scope 3';
    default:
      return 'Unknown';
  }
};
