import type * as Plot from '@observablehq/plot';

export const plotLegend = ({
  plot,
  legendPoints,
}: {
  plot: (SVGSVGElement | HTMLElement) & Plot.Plot;
  legendPoints: {
    key: string;
    text: string;
  }[];
}) => {
  return plot.legend('color', {
    legend: 'swatches',
    tickFormat: (key: string) =>
      legendPoints.find((e) => e.key === key)?.text ?? key,
    style: {
      fontSize: '12px',
      fontWeight: '450',
    },
    swatchWidth: 20,
    swatchHeight: 2,
  });
};
