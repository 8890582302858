import { Dropdown } from 'app/components/Dropdown';
import MissingDataError from 'app/components/Error/Custom/MissingDataError';
import InlineError from 'app/components/Error/InlineError';
import Loader from 'app/components/Loader';
import { useFetchWithAuth } from 'app/context/SWRContext';
import useEndpoint from 'app/lib/useEndpoint';
import { useTranslations } from 'next-intl';
import { useState } from 'react';
import { useSWRConfig } from 'swr';

const BaselineSection = () => {
  const fetchWithAuth = useFetchWithAuth();
  const t = useTranslations();
  const { mutate } = useSWRConfig();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { data: metadata } = useEndpoint('/metadata');

  const {
    data,
    isLoading,
    error: fetchingError,
  } = useEndpoint('/settings/baseline-options');

  const handleSave = async (year: number) => {
    try {
      setIsSaving(true);
      await fetchWithAuth('/settings/baseline', {
        method: 'POST',
        body: JSON.stringify({ year }),
      });
      await mutate('/metadata');
      setIsSaving(false);
    } catch (error) {
      let errorMessage = 'An unexpected error occurred. Please try again.';
      console.error(error);

      if (error instanceof Error) {
        errorMessage = error.message;
      }

      setError(errorMessage);
      setIsSaving(false);
    }
  };

  if (fetchingError || error) {
    return (
      <InlineError text={error || JSON.stringify(fetchingError)} type="error" />
    );
  }

  if (isLoading) {
    return <Loader type="inline" />;
  }

  if (!data || !data.options || data.options.length === 0) {
    return <MissingDataError />;
  }

  return (
    <div className="w-full flex justify-between">
      <div className="flex flex-col">
        <p className="font-bold">{t('BaselineSection.baselineYear')} </p>
        <p> {t('BaselineSection.baselineYearDescription')}</p>
      </div>
      <Dropdown.Default
        align="right"
        options={data.options.map((year) => ({
          label: year.toString(),
          value: year,
        }))}
        onChange={(e) => void handleSave(e)}
        value={metadata?.baselineYear || data.options[0]}
        disabled={isSaving}
      />
    </div>
  );
};

export default BaselineSection;
