import type { NetZeroChartDataset } from '../..';

export const getMaxFootprintFromDataset = (datasets: NetZeroChartDataset[]) =>
  datasets.reduce((acc, aDataSet) => {
    const dataMaxFootprint = aDataSet.data
      ? Math.max(...aDataSet.data.points.map((e) => e.value))
      : 0;
    let forecastMaxFootprint = 0;

    if (aDataSet.forecast) {
      if (aDataSet.forecast.type === 'percentageForecast') {
        const { startValue, yearlyVariation, startYear } = aDataSet.forecast;
        forecastMaxFootprint =
          startValue * (1 + yearlyVariation) ** (2050 - startYear);
      } else {
        if (!aDataSet.forecast.points.length) return Math.max(acc, 0);
        const endValue =
          aDataSet.forecast.points[aDataSet.forecast.points.length - 1].value;
        forecastMaxFootprint = Math.max(
          ...aDataSet.forecast.points.map((e) => e.value),
          endValue,
        );
      }
    }
    const maxFootprint = Math.max(dataMaxFootprint, forecastMaxFootprint);
    return Math.max(acc, maxFootprint);
  }, 0);
