import * as Plot from '@observablehq/plot';
import * as d3 from 'd3';

import type { NetZeroChartDataset } from '../..';

type GetForecastPlotProps = {
  forecast: NetZeroChartDataset['forecast'];
  settings: NetZeroChartDataset['settings'];
};
export const getForecastPlot = ({
  forecast,
  settings,
}: GetForecastPlotProps) => {
  if (!forecast) {
    return [];
  }

  if (forecast.type === 'pointsForecast') {
    return [
      Plot.lineY(
        forecast.points.map((aPoint) => ({
          year: +aPoint.year,
          value: +aPoint.value > 0 ? +aPoint.value : 0,
          type: settings.key,
        })),
        {
          x: 'year',
          y: 'value',
          stroke: 'type',
          strokeWidth: 2,
          curve: d3.curveBasis,
          ...(forecast.dashed ? { strokeDasharray: '4 4' } : {}),
        },
      ),
    ];
  }
  if (forecast.type === 'percentageForecast') {
    const { startYear, startValue, yearlyVariation, dashed } = forecast;
    const netZeroYears = d3.range(startYear, 2051);

    const d3NetZeroTrajectoryArray = netZeroYears.map((year, index) => ({
      year: year,
      value: startValue * (1 + yearlyVariation) ** index,
      type: settings.key,
    }));

    return [
      Plot.lineY(d3NetZeroTrajectoryArray, {
        x: 'year',
        y: 'value',
        stroke: 'type',
        strokeWidth: 2,
        ...(dashed ? { strokeDasharray: '4 4' } : {}),
      }),
    ];
  }
  return [];
};
