import * as Plot from '@observablehq/plot';

export const getXAxis = () =>
  Plot.axisX({
    tickSize: 6,
    tickFormat: 'd',
    tickPadding: 7,
    fill: '#989B9D',
    stroke: '#EFF0F0',
    label: null,
    fontVariant: 'normal',
  });
