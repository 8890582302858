'use client';
import MainNavigationPanel from 'app/[locale]/(auth)/root-layout/MainNavigationPanel';
import withAuthWall from 'app/components/AuthWall';
import AutomaticIncidentBanner from 'app/components/AutomaticIncidentBanner';
import useEndpoint from 'app/lib/useEndpoint';
import { usePathname, useRouter } from 'app/navigation';
import classNames from 'classnames';
import dayjs from 'dayjs';
import Image from 'next/image';
import Link from 'next/link';
import type { ReactNode } from 'react';

import * as React from 'react';
import SettingsActionList from './settings/SettingsActionList';

function AuthLayout({
  children,
  params,
}: {
  children?: ReactNode;
  params: Promise<{ locale: string }>;
}) {
  const { data } = useEndpoint('/settings/language');
  const router = useRouter();
  const pathname = usePathname();
  const language = data?.language;

  const { locale } = React.use(params);

  // If language from the DB is different from the one in localStorage, change it
  if (language && language !== locale) {
    router.replace(pathname, { locale: language });
  }
  dayjs.locale(locale);
  return (
    <div>
      <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
        <div className="flex flex-grow flex-col overflow-y-auto border-r border-gray-200 bg-gray-100 px-4">
          <div className="flex flex-shrink-0 items-center justify-between py-6 px-2">
            <Link href="/home">
              <Image
                className="h-3 w-32"
                width={128}
                height={12}
                src="/img/carbonfact.svg"
                alt="Carbonfact"
              />
            </Link>
          </div>
          <div className="flex flex-1 flex-col">
            <nav className="flex-1">
              <MainNavigationPanel />
            </nav>

            {/* Profile dropdown */}
            <SettingsActionList />
          </div>
        </div>
      </div>
      <div className={classNames('mx-auto flex flex-1 flex-col md:pl-64')}>
        <main>
          <AutomaticIncidentBanner />
          <div className="pb-6 md:pt-0 flex justify-center w-full">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
}

export default withAuthWall(AuthLayout);
