import classNames from 'classnames';
import Image from 'next/image';

interface InlineErrorProps {
  text: string;
  type: 'error';
  className?: string;
}

export default function InlineError({ text, className }: InlineErrorProps) {
  return (
    <div
      className={classNames(
        'h-8 p-2 bg-red-100 rounded justify-start items-center gap-2 inline-flex',
        className,
      )}
    >
      <div className="text-center text-red-500 text-xs font-medium flex flex-row items-center">
        <Image
          height={16}
          width={16}
          alt="warning-icon"
          src="/img/icons/warning.svg"
          className="mr-2"
        />
        {text}
      </div>
    </div>
  );
}
